import React, { useEffect, useState } from "react";
import "./doctorCases.scss";
import { CaseDetail } from "../../../models/CaseDetail/caseDetail.model";
import { CaseStatusEnum } from "../../../enums/caseStatus.enum";
import AppLoader from "../../../shared/components/AppLoader";
import AppCard from "../../../shared/components/AppCard";
import {
  Button,
  Drawer,
  Menu,
  Modal,
  Popconfirm,
  Popover,
  Table,
  Tooltip,
} from "antd";
import moment from "moment";
import DoctorCaseService from "../../../services/Case/DoctorCase/doctorCase.service";
import CaseOverview from "../../../shared/components/CaseOverview";
import { stopPropogateEvent } from "../../../shared/utils/eventUtils";
import { CaseBodyPart } from "../../../models/CaseBodyPart/caseBodyPart.model";
import {
  FileAddOutlined,
  DownloadOutlined,
  FileZipOutlined,
} from "@ant-design/icons";
import ReportForm from "../../../shared/components/ReportForm";
import ReportService from "../../../services/Report/report.service";
import { DownloadFileTypeEnum } from "../../../enums/downloadFileType.enum";
import { hasDoctorAccessToCase } from "../../../shared/utils/accessUtils";
import { UserRoleEnum } from "../../../enums/userRole.enum";
import CaseFilterForm from "../../../shared/components/CaseFilterForm";
import { CaseFilterParams } from "../../../models/CaseFilterParams/caseFilterParams.model";
import { formatStatus } from "../../../shared/utils/dataFormatConverter";

interface DoctorCasesProps {}

function DoctorCases(props: DoctorCasesProps) {
  const [caseDetails, setCaseDetails] = useState<CaseDetail[]>([]);

  const [loading, setLoading] = useState(false);

  const [downloadLoading, setDownloadLoading] = useState(false);

  const [downloadZipLoading, setDownloadZipLoading] = useState(false);

  const [activeCase, setActiveCase] = useState<CaseDetail>();

  const [showCaseOverview, setShowCaseOverview] = useState(false);

  const [showReportForm, setShowReportForm] = useState(false);

  const [formValues, setFormValues] = useState<CaseFilterParams>(
    Object.assign(new CaseFilterParams(), {
      ...new CaseFilterParams(),
      status: CaseStatusEnum.UNASSIGNED,
      fromDate: moment().format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
    })
  );

  const handleSubmit = (values: CaseFilterParams) => {
    const formValues = Object.assign(new CaseFilterParams(), values);
    setFormValues(formValues);
    handleFetchCases(formValues);
  };

  const handleReset = () => {
    const formValues = new CaseFilterParams();
    setFormValues(formValues);
    handleFetchCases(formValues);
  };

  const handleToggleReportForm = () => setShowReportForm(!showReportForm);

  const handleCloseCaseOverview = () => {
    setActiveCase(undefined);
    setShowCaseOverview(false);
  };

  const handleRowChange = (caseDetail: CaseDetail, _: number | undefined) => {
    return {
      onClick: (event: any) => {
        if (caseDetail?.status !== CaseStatusEnum.UNASSIGNED) {
          setActiveCase(caseDetail);
          setShowCaseOverview(true);
        }
      },
    };
  };

  const handleFetchCases = (formValues: CaseFilterParams) => {
    setLoading(true);
    DoctorCaseService.fetchDoctorCases(
      formValues,
      (caseDetails: CaseDetail[]) => {
        setCaseDetails(caseDetails);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  const handleDownloadDetails = (caseDetail: CaseDetail) => {
    setActiveCase(caseDetail);
    if (caseDetail?.id) {
      setDownloadZipLoading(true);
      DoctorCaseService.downloadDoctorCase(
        Number(caseDetail?.id),
        (targetUrl: any) => {
          window.open(targetUrl, "_blank");
        },
        () => {},
        () => {
          setDownloadZipLoading(false);
        }
      );
    }
  };

  const handleSelfAssignCase = (caseDetail: CaseDetail) => {
    DoctorCaseService.selfAssignCase(
      caseDetail,
      (caseDetail: CaseDetail) => {
        const caseIndex = caseDetails.findIndex(
          (caseItem) => caseItem.id === caseDetail.id
        );
        if (caseIndex >= 0) {
          caseDetails[caseIndex] = caseDetail;
        }
        setCaseDetails([...caseDetails]);
      },
      () => {},
      () => {}
    );
  };

  const handleDownloadReport = (
    caseDetail: CaseDetail,
    caseType: DownloadFileTypeEnum,
    withLetterHead: boolean
  ) => {
    setActiveCase(caseDetail);
    setDownloadLoading(true);
    if (caseDetail?.id) {
      ReportService.downloadReport(
        caseType,
        caseDetail,
        withLetterHead,
        (reportUrl: string) => {
          window.open(reportUrl, "_blank");
        },
        () => {},
        () => {
          setActiveCase(undefined);
          setDownloadLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    handleFetchCases(formValues);
  }, []);

  const columns = [
    {
      title: "Patient name",
      dataIndex: "patientName",
      key: "patientName",
    },
    {
      title: "Scan Center",
      dataIndex: "scanCenterName",
      key: "scanCenterName",
    },
    {
      title: "Date Uploaded",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => (
        <span>{date ? moment(date).format("DD-MMM-YYYY hh:mm A") : "NA"}</span>
      ),
    },
    {
      title: "Scan Type",
      dataIndex: "scanTypeName",
      key: "scanTypeName",
    },
    {
      title: "Body parts",
      dataIndex: "caseBodyParts",
      key: "caseBodyParts",
      render: (caseBodyParts: CaseBodyPart[]) => (
        <div>
          {caseBodyParts.map((bodyPart) => bodyPart.bodyPartName).join(", ")}
        </div>
      ),
    },
    {
      title: "Due Time",
      dataIndex: "dueDate",
      key: "dueDate",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string, caseDetail: CaseDetail) => {
        return (
          <div
            className="doctor-cases__status-wrapper"
            onClick={stopPropogateEvent}
          >
            <span className={`doctor-cases__status ${status}`}>
              {formatStatus(status)}
            </span>
            {status === CaseStatusEnum.UNASSIGNED && (
              <Popconfirm
                title="Are you sure?"
                placement="left"
                onConfirm={() => {
                  handleSelfAssignCase(caseDetail);
                }}
                okText="Yes, Assign to me"
                cancelText="No"
              >
                <Button className="ml-2" type="primary">
                  Self Assign
                </Button>
              </Popconfirm>
            )}
            {caseDetail?.status === CaseStatusEnum.ASSIGNED && (
              <Tooltip title="Generate Report">
                <Button
                  icon={<FileAddOutlined />}
                  className="ml-2"
                  onClick={() => {
                    setActiveCase(caseDetail);
                    handleToggleReportForm();
                  }}
                />
              </Tooltip>
            )}
            {caseDetail?.status === CaseStatusEnum.REPORTED && (
              <Popover
                destroyTooltipOnHide
                overlayClassName="admin-cases__assignment-popover"
                placement="bottomLeft"
                content={
                  <div>
                    <Menu mode="vertical" className="border-none">
                      <Menu.Item
                        className="navbar-item m-0"
                        key="with_letterhead"
                        onClick={() => {
                          handleDownloadReport(
                            caseDetail,
                            DownloadFileTypeEnum.PDF,
                            true
                          );
                        }}
                      >
                        Download with Letterhead
                      </Menu.Item>
                      <Menu.Item
                        className="navbar-item m-0"
                        key="without_letterhead"
                        onClick={() => {
                          handleDownloadReport(
                            caseDetail,
                            DownloadFileTypeEnum.PDF,
                            false
                          );
                        }}
                      >
                        Download without Letterhead
                      </Menu.Item>
                      <Menu.Item
                        className="navbar-item m-0"
                        key="as_word"
                        onClick={() => {
                          handleDownloadReport(
                            caseDetail,
                            DownloadFileTypeEnum.WORD,
                            false
                          );
                        }}
                      >
                        Download as Word
                      </Menu.Item>
                    </Menu>
                  </div>
                }
                title="Download Report"
                trigger="click"
              >
                <Button
                  loading={activeCase?.id === caseDetail?.id && downloadLoading}
                  icon={<DownloadOutlined />}
                  className="ml-2"
                />
              </Popover>
            )}
            {hasDoctorAccessToCase(UserRoleEnum.DOCTOR, caseDetail) && (
              <Tooltip title="Download Case">
                <Button
                  loading={
                    activeCase?.id === caseDetail?.id && downloadZipLoading
                  }
                  icon={<FileZipOutlined />}
                  className="ml-2"
                  onClick={() => {
                    handleDownloadDetails(caseDetail);
                  }}
                />
              </Tooltip>
            )}
          </div>
        );
      },
      width: 200,
    },
  ];

  return (
    <div className="doctor-cases">
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <AppCard>
          <h2 className="mt-2">Cases</h2>
          <CaseFilterForm
            isDoctor
            formValues={formValues}
            onSubmit={handleSubmit}
            onReset={handleReset}
          />
          <Table
            dataSource={caseDetails}
            rowClassName="cursor-pointer"
            onRow={handleRowChange}
            columns={columns}
          />
          <Modal
            destroyOnClose
            visible={showCaseOverview}
            className="primary-modal"
            onCancel={handleCloseCaseOverview}
            footer={null}
            maskClosable={false}
          >
            {activeCase?.id && (
              <CaseOverview
                activeCaseId={activeCase?.id}
                onCancel={handleCloseCaseOverview}
              />
            )}
          </Modal>
        </AppCard>
      )}
      <Drawer
        destroyOnClose
        title={<h2>Generate Report</h2>}
        width="70%"
        onClose={handleToggleReportForm}
        visible={showReportForm}
        bodyStyle={{ paddingBottom: 80 }}
        maskClosable={false}
        footer={null}
      >
        {activeCase?.id && (
          <ReportForm
            onClose={handleToggleReportForm}
            caseDetailId={activeCase.id}
            onPublish={() => handleFetchCases(formValues)}
          />
        )}
      </Drawer>
    </div>
  );
}

export default DoctorCases;
