import React, { useEffect, useState } from "react";
import "./doctorForm.scss";
import { Form, Formik, FormikValues } from "formik";
import { Button, Col, Row, Switch } from "antd";
import InputField from "../../../shared/components/InputField";
import { doctorFormValidation } from "./doctorFormValidation";
import { Doctor } from "../../../models/Doctor/doctor.model";
import FileUpload from "../../../shared/components/FileUpload";
import DoctorService from "../../../services/Doctor/doctor.service";

interface DoctorFormProps {
  doctor: Doctor;
  onSuccess: (doctor: Doctor) => void;
}

function DoctorForm({ doctor, onSuccess }: DoctorFormProps) {
  const [formValues, setFormValues] = useState<Doctor>(doctor);

  const [formLoading, setFormLoading] = useState(false);

  const handleSubmit = (values: FormikValues) => {
    const doctor = Object.assign(new Doctor(), values);
    setFormLoading(true);
    if (doctor.id) {
      DoctorService.updateDoctor(
        doctor,
        (doctor: Doctor) => {
          onSuccess(doctor);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    } else {
      doctor.password = `${doctor?.firstName || ""}${doctor?.lastName || ""}`;
      DoctorService.createDoctor(
        doctor,
        (doctor: Doctor) => {
          onSuccess(doctor);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    if (doctor.id) {
      DoctorService.showDoctor(
        doctor.id,
        (doctor: Doctor) => {
          setFormValues(doctor);
        },
        () => {},
        () => {}
      );
    }
  }, [doctor]);

  return (
    <div className="doctor-form">
      <h2 className="text-primary">
        {doctor.id ? "Edit Doctor" : "Add Doctor"}
      </h2>
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={doctorFormValidation}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <Form>
              <Row gutter={[30, 0]}>
                <Col span={12}>
                  <InputField
                    title="First Name"
                    type="text"
                    name="firstName"
                    placeholder="Enter First name"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Last Name"
                    type="text"
                    name="lastName"
                    placeholder="Enter Last name"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Email"
                    type="email"
                    name="email"
                    placeholder="Enter Email"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Mobile"
                    type="text"
                    name="mobileNumber"
                    placeholder="Enter Mobile"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Address"
                    type="text"
                    name="address"
                    placeholder="Enter Address"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Specialisation"
                    type="text"
                    name="specialisation"
                    placeholder="Enter Specialisation"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="State of registration"
                    type="text"
                    name="stateOfRegistration"
                    placeholder="Enter State of registration"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Medical council registration number"
                    type="text"
                    name="medicalCouncilRegistrationNumber"
                    placeholder="Enter Registration number"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Assignment limit/ week"
                    type="number"
                    name="assignmentsPerWeek"
                    placeholder="Enter Assignment Limit"
                  />
                </Col>
                {!values?.id && (
                  <Col span={12}>
                    <InputField
                      title="Password"
                      type="text"
                      value={`${values?.firstName || ""}${
                        values?.lastName || ""
                      }`}
                      name="password"
                      placeholder="Your Password"
                    />
                  </Col>
                )}
                <Col span={12}>
                  <div className="doctor-form__switch">
                    <div className="doctor-form__switch-title">
                      Approval Required?
                    </div>
                    <Switch
                      checked={values?.approvalRequired}
                      onChange={(checked) => {
                        setFieldValue("approvalRequired", checked);
                      }}
                    />
                  </div>
                </Col>
                <Col span={12}>
                  <FileUpload
                    name="doctorSignature"
                    title="Upload Signature"
                    setFieldValue={setFieldValue}
                    value={values.doctorSignature}
                  />
                </Col>
              </Row>
              <div className="doctor-form__submit-wrapper text-right mt-3">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={formLoading}
                  disabled={!isValid || formLoading}
                >
                  {doctor?.id ? "Update" : "Add"}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default DoctorForm;
