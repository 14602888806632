import React, { useCallback, useEffect, useState } from "react";
import "./appSidebar.scss";
import { Menu } from "antd";
import { useHistory } from "react-router-dom";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import AuthContainer from "../../../store/container/AuthContainer";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { UserRoleEnum } from "../../../enums/userRole.enum";
import ActionCable, { Cable } from "actioncable";
import { getHeaders } from "../../../interceptor/axiosInstance";
import { ScanCenter } from "../../../models/ScanCenter/scanCenter.model";
import ProfileService from "../../../services/Profile/profile.service";
import CaseContainer from "../../../store/container/CaseContainer";
import { CaseReducerProps } from "../../../store/reducers/caseReducer";
import { CaseUploadStatusEnum } from "../../../enums/caseUploadStatus.enum";

interface AppSidebarProps extends AuthReducerProps, CaseReducerProps {}

function AppSidebar({
  authenticated,
  userRole,
  setUploadStart,
  setUploadInProgress,
  setUploadComplete,
}: AppSidebarProps) {
  const history = useHistory();

  const [pathname, setPathname] = useState<string>(history.location.pathname);

  const [scanCenter, setScanCenter] = useState<ScanCenter>();

  const handleClick = (e: any) => {
    history.push(e.key);
  };

  const handleSetPendingCaseIds = (data: {
    message: string;
    case_detail_id: number;
  }) => {
    switch (data.message) {
      case CaseUploadStatusEnum.CASE_UPLOAD_STARTED: {
        setUploadStart(data["case_detail_id"]);
        break;
      }
      case CaseUploadStatusEnum.CASE_UPLOAD_IN_PROGRESS: {
        setUploadInProgress(data["case_detail_id"]);
        break;
      }
      case CaseUploadStatusEnum.CASE_UPLOAD_COMPLETE: {
        setUploadComplete(data["case_detail_id"]);
        break;
      }
    }
  };

  useEffect(() => {
    return history.listen((location) => {
      setPathname(location.pathname);
    });
  }, [history]);

  useEffect(() => {
    if (authenticated && userRole === UserRoleEnum.SCAN_CENTER) {
      ProfileService.getProfile(
        userRole,
        (scanCenter: ScanCenter) => {
          setScanCenter(scanCenter);
        },
        () => {},
        () => {}
      );
    }
  }, [authenticated, userRole]);

  useEffect(() => {
    if (userRole === UserRoleEnum.SCAN_CENTER && scanCenter) {
      const headers = getHeaders();
      const cable = ActionCable.createConsumer(
        `${process.env.REACT_APP_SOCKET_URL}?access-token=${headers["access-token"]}&client=${headers["client"]}&uid=${headers["uid"]}`
      );
      cable.subscriptions.create(
        {
          channel: "NotificationsChannel",
          room: `notifications:${scanCenter?.id}`,
        },
        {
          connected: () => {
            console.log("connected");
          },
          disconnected: () => {
            console.log("disconnected");
          },
          received: handleSetPendingCaseIds,
        }
      );
    }
  }, [userRole, scanCenter]);

  return authenticated ? (
    <div className="app-sidebar">
      <h1 className="app-sidebar__logo">CONRAD</h1>
      <h5 className="app-sidebar__logo-subtext">TELERADIOLOGY</h5>
      <Menu
        selectedKeys={[pathname]}
        onClick={handleClick}
        defaultSelectedKeys={[AppRoutes.HOME]}
        mode="inline"
      >
        <Menu.Item key={AppRoutes.HOME}>
          <i className="icon-dashboard" />
          <div className="app-sidebar__menu-title">Cases</div>
        </Menu.Item>
        {userRole === UserRoleEnum.ADMIN && (
          <React.Fragment>
            <Menu.Item key={AppRoutes.SCAN_CENTRES}>
              <i className="icon-dashboard" />
              <div className="app-sidebar__menu-title">Scan Centers</div>
            </Menu.Item>
            <Menu.Item key={AppRoutes.DOCTORS}>
              <i className="icon-dashboard" />
              <div className="app-sidebar__menu-title">Doctors</div>
            </Menu.Item>
            <Menu.Item key={AppRoutes.ADMINS}>
              <i className="icon-dashboard" />
              <div className="app-sidebar__menu-title">Admins</div>
            </Menu.Item>
            <Menu.Item key={AppRoutes.SCAN_TYPES}>
              <i className="icon-dashboard" />
              <div className="app-sidebar__menu-title">Scan Types</div>
            </Menu.Item>
            <Menu.Item key={AppRoutes.BODY_PARTS}>
              <i className="icon-dashboard" />
              <div className="app-sidebar__menu-title">Body Parts</div>
            </Menu.Item>
            {/*<Menu.Item key={AppRoutes.ISSUES}>
              <i className="icon-dashboard" />
              <div className="app-sidebar__menu-title">Issues</div>
            </Menu.Item>*/}
          </React.Fragment>
        )}
        {(userRole === UserRoleEnum.DOCTOR ||
          userRole === UserRoleEnum.ADMIN) && (
          <React.Fragment>
            <Menu.Item key={AppRoutes.REPORT_TEMPLATES}>
              <i className="icon-dashboard" />
              <div className="app-sidebar__menu-title">Report Templates</div>
            </Menu.Item>
          </React.Fragment>
        )}
        <Menu.Item key={AppRoutes.PROFILE}>
          <i className="icon-dashboard" />
          <div className="app-sidebar__menu-title">Profile</div>
        </Menu.Item>
      </Menu>
    </div>
  ) : null;
}

export default CaseContainer(AuthContainer(AppSidebar));
