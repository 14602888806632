import React, { useEffect, useState } from "react";
import "./scanTypeList.scss";
import ScanTypeService from "../../../services/ScanType/scanType.service";
import { ScanType } from "../../../models/ScanType/scanType.model";
import { Button, Col, Modal, Row, Table } from "antd";
import AppLoader from "../../../shared/components/AppLoader";
import AppCard from "../../../shared/components/AppCard";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { BodyPart } from "../../../models/BodyPart/bodyPart.model";
import { Form, Formik } from "formik";
import InputField from "../../../shared/components/InputField";
import BodyPartService from "../../../services/BodyPart/bodyPart.service";

interface ScanTypeListProps {}

function ScanTypeList(props: ScanTypeListProps) {
  const [loading, setLoading] = useState(false);

  const [scanTypes, setScanTypes] = useState<ScanType[]>([]);

  const [formLoading, setFormLoading] = useState(false);

  const [showScanTypeForm, setShowScanTypeForm] = useState(false);

  const [activeScanType, setActiveScanType] = useState(new ScanType());

  const handleToggleScanTypeForm = () => setShowScanTypeForm(!showScanTypeForm);

  const handleAddScanType = () => {
    setActiveScanType(new ScanType());
    handleToggleScanTypeForm();
  };

  const handleEdit = (scanType: ScanType) => () => {
    setActiveScanType(scanType);
    handleToggleScanTypeForm();
  };

  const handleSubmit = (values: ScanType) => {
    const scanType = Object.assign(new ScanType(), values);
    setFormLoading(true);
    if (scanType.id) {
      ScanTypeService.updateScanType(
        scanType,
        (scanType: ScanType) => {
          const scanTypeIndex = scanTypes.findIndex(
            (scanTypeItem) => scanTypeItem.id === scanType.id
          );
          if (scanTypeIndex >= 0) scanTypes[scanTypeIndex] = scanType;
          setScanTypes([...scanTypes]);
          handleToggleScanTypeForm();
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    } else {
      ScanTypeService.createScanType(
        scanType,
        (scanType: ScanType) => {
          scanTypes.unshift(scanType);
          setScanTypes([...scanTypes]);
          handleToggleScanTypeForm();
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    setLoading(true);
    ScanTypeService.fetchScanTypes(
      (scanTypes: ScanType[]) => {
        setScanTypes(scanTypes);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, []);

  const columns = [
    {
      title: "Scan Type",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id: number, scanType: ScanType) => (
        <Button icon={<EditOutlined />} onClick={handleEdit(scanType)} />
      ),
    },
  ];

  return (
    <div className="scan-type-list">
      <AppCard>
        <h2>
          Scan Types
          <Button
            type="primary"
            className="float-right"
            onClick={handleAddScanType}
          >
            <PlusOutlined />
            Add Scan Type
          </Button>
        </h2>
        {loading ? (
          <AppLoader loading={loading} />
        ) : (
          <Table columns={columns} dataSource={scanTypes} />
        )}
      </AppCard>
      <Modal
        className="primary-modal"
        footer=""
        keyboard={false}
        maskClosable={false}
        destroyOnClose
        visible={showScanTypeForm}
        onCancel={handleToggleScanTypeForm}
      >
        <h2 className="text-primary">
          {activeScanType?.id ? "Edit Scan Type" : "Add Scan Type"}
        </h2>
        {activeScanType && (
          <Formik
            initialValues={activeScanType}
            onSubmit={handleSubmit}
            enableReinitialize
            validationSchema={null}
          >
            {({ values }) => {
              return (
                <Form>
                  <Row gutter={[30, 0]}>
                    <Col span={12}>
                      <InputField
                        title="Name"
                        type="text"
                        name="name"
                        placeholder="Enter Scan type name"
                      />
                    </Col>
                  </Row>
                  <div className="text-right mt-3">
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={formLoading}
                      disabled={!values?.name || formLoading}
                    >
                      {activeScanType?.id ? "Update" : "Add"}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </Modal>
    </div>
  );
}

export default ScanTypeList;
