import React, { useEffect, useState } from "react";
import "./bodyPartList.scss";
import AppCard from "../../../shared/components/AppCard";
import AppLoader from "../../../shared/components/AppLoader";
import { Button, Col, Modal, Row, Table } from "antd";
import { BodyPart } from "../../../models/BodyPart/bodyPart.model";
import BodyPartService from "../../../services/BodyPart/bodyPart.service";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Formik } from "formik";
import InputField from "../../../shared/components/InputField";

interface BodyPartListProps {}

function BodyPartList(props: BodyPartListProps) {
  const [loading, setLoading] = useState(false);

  const [bodyParts, setBodyParts] = useState<BodyPart[]>([]);

  const [showBodyPartForm, setShowBodyPartForm] = useState(false);

  const [activeBodyPart, setActiveBodyPart] = useState(new BodyPart());

  const [formLoading, setFormLoading] = useState(false);

  const handleSubmit = (values: BodyPart) => {
    const bodyPart = Object.assign(new BodyPart(), values);
    setFormLoading(true);
    if (bodyPart.id) {
      BodyPartService.updateBodyPart(
        bodyPart,
        (bodyPart: BodyPart) => {
          const bodyPartIndex = bodyParts.findIndex(
            (bodyPartItem) => bodyPartItem.id === bodyPart.id
          );
          if (bodyPartIndex >= 0) bodyParts[bodyPartIndex] = bodyPart;
          setBodyParts([...bodyParts]);
          handleToggleBodyPartForm();
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    } else {
      BodyPartService.createBodyPart(
        bodyPart,
        (bodyPart: BodyPart) => {
          bodyParts.unshift(bodyPart);
          setBodyParts([...bodyParts]);
          handleToggleBodyPartForm();
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  const handleToggleBodyPartForm = () => setShowBodyPartForm(!showBodyPartForm);

  const handleAddBodyPart = () => {
    setActiveBodyPart(new BodyPart());
    handleToggleBodyPartForm();
  };

  const handleEdit = (bodyPart: BodyPart) => () => {
    setActiveBodyPart(bodyPart);
    handleToggleBodyPartForm();
  };

  const columns = [
    {
      title: "Body Part",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id: number, bodyPart: BodyPart) => (
        <Button icon={<EditOutlined />} onClick={handleEdit(bodyPart)} />
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    BodyPartService.fetchBodyParts(
      (bodyParts: BodyPart[]) => {
        setBodyParts(bodyParts);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, []);

  return (
    <div className="body-part-list">
      <AppCard>
        <h2>
          Body Parts
          <Button
            type="primary"
            className="float-right"
            onClick={handleAddBodyPart}
          >
            <PlusOutlined />
            Add Body Part
          </Button>
        </h2>
        {loading ? (
          <AppLoader loading={loading} />
        ) : (
          <Table columns={columns} dataSource={bodyParts} />
        )}
      </AppCard>
      <Modal
        className="primary-modal"
        footer=""
        keyboard={false}
        maskClosable={false}
        destroyOnClose
        visible={showBodyPartForm}
        onCancel={handleToggleBodyPartForm}
      >
        <h2 className="text-primary">
          {activeBodyPart?.id ? "Edit Body Part" : "Add Body Part"}
        </h2>
        {activeBodyPart && (
          <Formik
            initialValues={activeBodyPart}
            onSubmit={handleSubmit}
            enableReinitialize
            validationSchema={null}
          >
            {({ values }) => {
              return (
                <Form>
                  <Row gutter={[30, 0]}>
                    <Col span={12}>
                      <InputField
                        title="Name"
                        type="text"
                        name="name"
                        placeholder="Enter Body part name"
                      />
                    </Col>
                  </Row>
                  <div className="text-right mt-3">
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={formLoading}
                      disabled={!values?.name || formLoading}
                    >
                      {activeBodyPart?.id ? "Update" : "Add"}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </Modal>
    </div>
  );
}

export default BodyPartList;
