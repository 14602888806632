import React, { useEffect, useState } from "react";
import "./caseForm.scss";
import { CaseDetail } from "../../../../models/CaseDetail/caseDetail.model";
import { Form, Formik } from "formik";
import { Button, Col, notification, Row } from "antd";
import InputField from "../../../../shared/components/InputField";
import FileUpload from "../../../../shared/components/FileUpload";
import { caseFileValidation, caseFormValidation } from "./caseFormValidation";
import {
  getDropdownOptions,
  IDropdownOptions,
} from "../../../../shared/utils/dropdownUtils";
import DropdownField from "../../../../shared/components/DropdownField";
import { DropdownModeEnum } from "../../../../enums/dropdownMode.enum";
import ScanCenterCaseService from "../../../../services/Case/ScanCenterCase/scanCenterCase.service";
import { CaseBodyPart } from "../../../../models/CaseBodyPart/caseBodyPart.model";
import ScanTypeService from "../../../../services/ScanType/scanType.service";
import { ScanType } from "../../../../models/ScanType/scanType.model";
import BodyPartService from "../../../../services/BodyPart/bodyPart.service";
import { BodyPart } from "../../../../models/BodyPart/bodyPart.model";
import CaseContainer from "../../../../store/container/CaseContainer";
import { CaseReducerProps } from "../../../../store/reducers/caseReducer";
import { ScanCenterBranch } from "../../../../models/ScanCenterBranch/scanCenterBranch.model";
import AuthContainer from "../../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../../store/reducers/authReducer";
import ProgressBar from "../../../../shared/components/ProgressBar";

interface CaseFormProps extends CaseReducerProps, AuthReducerProps {
  caseDetail: CaseDetail;
  onSuccess: (caseDetail: CaseDetail) => void;
  onClose?: () => void;
}

function CaseForm({
  caseDetail,
  user,
  onSuccess,
  onClose,
  setPending,
}: CaseFormProps) {
  const [formLoading, setFormLoading] = useState(false);

  const [fileUploadLoading, setFileUploadLoading] = useState(false);

  const [formValues, setFormValues] = useState(caseDetail);

  const [showFileUpload, setShowFileUpload] = useState(false);

  const [caseDetailId, setCaseDetailId] = useState<number | undefined>(
    caseDetail?.id
  );

  const genderOptions: IDropdownOptions[] = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Transgender", value: "transgender" },
  ];
  const contrastOptions: IDropdownOptions[] = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const [progressPercent, setProgressPercent] = useState(0);

  const [scanTypeOptions, setScanTypeOptions] = useState<IDropdownOptions[]>(
    []
  );

  const [bodyPartOptions, setBodyPartOptions] = useState<IDropdownOptions[]>(
    []
  );

  const [scanCenterBranchOptions, setScanCenterBranchOptions] = useState<
    IDropdownOptions[]
  >([]);

  const [presignedUrl, setCasePresignedUrl] = useState<string>();

  const [fileName, setFileName] = useState<string>();

  const [recentlyAddedCaseId, setRecentlyAddedCaseId] = useState<number>();

  const handleSubmit = (values: CaseDetail) => {
    const caseDetail = Object.assign(new CaseDetail(), values);
    caseDetail.caseBodyParts = caseDetail.caseBodyPartIds.map((bodyPartId) =>
      Object.assign(new CaseBodyPart(), {
        bodyPartId,
      })
    );
    setFormLoading(true);
    if (caseDetail.id) {
      ScanCenterCaseService.updateScanCenterCase(
        caseDetail,
        (caseDetail: CaseDetail) => {
          setCaseDetailId(caseDetail?.id);
          onSuccess(caseDetail);
          setShowFileUpload(true);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    } else {
      ScanCenterCaseService.createScanCenterCase(
        caseDetail,
        (caseDetail: CaseDetail) => {
          setRecentlyAddedCaseId(caseDetail?.id);
          if (caseDetail?.id) {
            ScanCenterCaseService.getCasePresignedURL(
              caseDetail?.id,
              ({ url, fileName }: { url: string; fileName: string }) => {
                setCasePresignedUrl(url);
                setFileName(fileName);
              },
              () => {},
              () => {}
            );
          }
          setCaseDetailId(caseDetail?.id);
          onSuccess(caseDetail);
          setShowFileUpload(true);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  const handleFileSubmit = async (values: { zipFile?: File }) => {
    const { zipFile } = values;
    if (presignedUrl && zipFile) {
      setFileUploadLoading(true);
      const xhr = new XMLHttpRequest();
      const formData = new FormData();
      formData.append("file", zipFile);
      xhr.open("PUT", presignedUrl);
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          setProgressPercent(percentComplete);
        }
      };
      xhr.addEventListener("load", (e) => {
        console.log("Success");
        setFileUploadLoading(false);
        if (recentlyAddedCaseId && fileName) {
          ScanCenterCaseService.updateFileUploadStatus(
            recentlyAddedCaseId,
            fileName,
            () => {
              onClose && onClose();
            },
            () => {},
            () => {}
          );
        }
      });
      xhr.addEventListener("error", () => {
        console.log("Failed");
        setFileUploadLoading(false);
      });
      xhr.send(formData);
    }
    // setFileUploadLoading(true);
    // if (zipFile && caseDetailId) {
    //   if (caseDetailId) {
    //     setPending(caseDetailId);
    //   }
    //   onClose && onClose();
    //   ScanCenterCaseService.uploadScanCenterCaseFile(
    //     caseDetailId,
    //     zipFile,
    //     () => {},
    //     () => {},
    //     () => {
    //       setFileUploadLoading(false);
    //       notification.close(caseDetailId.toString());
    //     }
    //   );
    // }
  };

  useEffect(() => {
    ScanTypeService.fetchScanTypes(
      (scanTypes: ScanType[]) => {
        setScanTypeOptions(getDropdownOptions(scanTypes, "name", "id"));
      },
      () => {},
      () => {}
    );
    if (user && user?.id) {
      ScanTypeService.fetchScanCenterBranches(
        Number(user.id),
        (scanCenterBranches: ScanCenterBranch[]) => {
          setScanCenterBranchOptions(
            getDropdownOptions(scanCenterBranches, "name", "id")
          );
        },
        () => {},
        () => {}
      );
    }

    BodyPartService.fetchBodyParts(
      (bodyParts: BodyPart[]) => {
        setBodyPartOptions(getDropdownOptions(bodyParts, "name", "id"));
      },
      () => {},
      () => {}
    );
  }, []);

  useEffect(() => {
    if (caseDetail && caseDetail.caseBodyParts) {
      caseDetail.caseBodyPartIds = caseDetail.caseBodyParts.map(
        (caseBodyPart: CaseBodyPart) => caseBodyPart.bodyPartId || 0
      );
      setFormValues(Object.assign(new CaseDetail(), { ...caseDetail }));
    }
  }, [caseDetail]);

  return (
    <div className="case-form">
      <h2 className="text-primary">
        {caseDetail.id ? "Edit Case" : "Add Case"}
      </h2>
      {showFileUpload ? (
        <Formik
          initialValues={{}}
          onSubmit={handleFileSubmit}
          enableReinitialize
          validationSchema={caseFileValidation}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            isValid,
            dirty,
            setFieldValue,
          }) => {
            return (
              <Form>
                <Row gutter={[30, 0]}>
                  <Col span={12}>
                    <FileUpload
                      name="zipFile"
                      title="Upload attachment"
                      accept=".zip"
                      setFieldValue={setFieldValue}
                      value={values.zipFile}
                    />
                  </Col>
                  {progressPercent > 0 && (
                    <Col span={24}>
                      <div style={{ margin: "1rem 0 0" }}>Upload Progress</div>
                      <ProgressBar
                        progress={parseInt(progressPercent.toString(), 10)}
                      />
                    </Col>
                  )}
                </Row>
                <div className="case-form__submit-wrapper text-right mt-3">
                  {progressPercent === 100 ? (
                    <Button type="primary" htmlType="button">
                      Close
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={fileUploadLoading}
                      disabled={!isValid || fileUploadLoading}
                    >
                      Upload File
                    </Button>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Formik
          initialValues={formValues}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={caseFormValidation}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            isValid,
            dirty,
            setFieldValue,
          }) => {
            return (
              <Form>
                <Row gutter={[30, 0]}>
                  <Col span={12}>
                    <InputField
                      title="Patient ID"
                      type="text"
                      name="patientId"
                      placeholder="Enter Patient ID"
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Patient name"
                      type="text"
                      name="patientName"
                      placeholder="Enter Patient name"
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      placeHolder="Select Scan Type"
                      showSearch
                      title="Scan Type"
                      name="scanTypeId"
                      setFieldValue={setFieldValue}
                      options={scanTypeOptions}
                      value={values.scanTypeId}
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      placeHolder="Select Body parts"
                      showSearch
                      mode={DropdownModeEnum.MULTIPLE}
                      title="Body parts"
                      name="caseBodyPartIds"
                      setFieldValue={setFieldValue}
                      options={bodyPartOptions}
                      value={values.caseBodyPartIds}
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      placeHolder="Select Scan Center Branch"
                      showSearch
                      title="Scan Center Branch"
                      name="scanCenterBranchId"
                      setFieldValue={setFieldValue}
                      options={scanCenterBranchOptions}
                      value={values.scanCenterBranchId}
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Age"
                      type="number"
                      name="age"
                      placeholder="Enter age"
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      placeHolder="Select Gender"
                      showSearch
                      title="Gender"
                      name="gender"
                      setFieldValue={setFieldValue}
                      options={genderOptions}
                      value={values.gender}
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Current clinical problem"
                      type="text"
                      name="currentClinicalProblem"
                      placeholder="Enter current clinical problem"
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      placeHolder="Select Contrast"
                      showSearch
                      title="Contrast"
                      name="contrast"
                      setFieldValue={setFieldValue}
                      options={contrastOptions}
                      value={values.contrast}
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Referring Doctor"
                      type="text"
                      name="referringDoctor"
                      placeholder="Enter Referring Doctor"
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Patient History"
                      type="textarea"
                      name="patientHistory"
                      placeholder="Enter Patient History"
                    />
                  </Col>
                </Row>
                <div className="case-form__submit-wrapper text-right mt-3">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={formLoading}
                    disabled={!isValid || formLoading}
                  >
                    {caseDetail?.id ? "Update" : "Add"}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
}

export default AuthContainer(CaseContainer(CaseForm));
