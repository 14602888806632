import React from "react";
import AuthRouter from "../authRouters";
import logo from "../../../assets/images/telerad-logo.png";
import "./authWrapper.scss";

const AuthWrapper = () => {
  return (
    <div className="auth-wrapper">
      <div className="auth-wrapper__logo-section">
        <div className="auth-wrapper__logo-section-content">
          <h1 className="auth-wrapper__logo">CONRAD</h1>
          <h5 className="auth-wrapper__logo-subtext">TELERADIOLOGY</h5>
        </div>
      </div>
      <div className="auth-wrapper__auth-section">
        <AuthRouter />
      </div>
    </div>
  );
};

export default AuthWrapper;
