import React, { useEffect, useState } from "react";
import AppCard from "../../../shared/components/AppCard";
import { Button, Table, Modal, Popconfirm, Tooltip } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import "./scanCenterList.scss";
import ScanCenterForm from "../ScanCenterForm";
import { ScanCenter } from "../../../models/ScanCenter/scanCenter.model";
import ScanCenterService from "../../../services/ScanCenter/scanCenter.service";
import { PlusOutlined } from "@ant-design/icons";
import AppLoader from "../../../shared/components/AppLoader";
import { generatePath, Link } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";

interface ScanCenterListProps {}

function ScanCenterList(props: ScanCenterListProps) {
  const [scanCenters, setScanCenters] = useState<ScanCenter[]>([]);

  const [showScanCenterForm, setShowScanCenterForm] = useState(false);

  const [loading, setLoading] = useState(false);

  const [activeScanCenter, setActiveScanCenter] = useState<ScanCenter>(
    new ScanCenter()
  );

  const handleCloseScanCenterForm = () => setShowScanCenterForm(false);

  const handleAddScanCenter = () => {
    setActiveScanCenter(new ScanCenter());
    setShowScanCenterForm(true);
  };

  const handleDeleteScanCenter = (scanCenter: ScanCenter) => {
    // console.log(scanCenter);
  };

  const handleScanCenterFormSuccess = (scanCenter: ScanCenter) => {
    setShowScanCenterForm(false);
    const scanCenterIndex = scanCenters.findIndex(
      (currentScanCenter) => scanCenter.id === currentScanCenter.id
    );
    if (scanCenterIndex >= 0) {
      scanCenters[scanCenterIndex] = scanCenter;
    } else {
      scanCenters.unshift(scanCenter);
    }
    setScanCenters([...scanCenters]);
  };

  useEffect(() => {
    setLoading(true);
    ScanCenterService.fetchScanCenters(
      (scanCenters: ScanCenter[]) => {
        setScanCenters(scanCenters);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Action",
      key: "action",
      render: (text: string, scanCenter: ScanCenter) => {
        return (
          <div className="scan-center-list_actions">
            <EditOutlined
              onClick={() => {
                setShowScanCenterForm(true);
                setActiveScanCenter(scanCenter);
              }}
            />
            <Popconfirm
              title="Are you sure to delete this Scan center?"
              placement="left"
              onConfirm={() => {
                handleDeleteScanCenter(scanCenter);
              }}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined />
            </Popconfirm>
            <Tooltip title="View summary">
              <Link
                to={generatePath(AppRoutes.SCAN_CENTER_SUMMARY, {
                  scanCenterId: scanCenter?.id,
                })}
              >
                <FileTextOutlined />
              </Link>
            </Tooltip>
          </div>
        );
      },
      width: 150,
    },
  ];

  return (
    <div className="scan-center-list">
      <AppCard>
        <h2>
          Scan Centers
          <Button
            type="primary"
            className="float-right"
            onClick={handleAddScanCenter}
          >
            <PlusOutlined />
            Add Scan center
          </Button>
        </h2>
        {loading ? (
          <AppLoader loading={loading} />
        ) : (
          <Table dataSource={scanCenters} columns={columns} />
        )}
      </AppCard>
      <Modal
        className="primary-modal"
        footer=""
        keyboard={false}
        maskClosable={false}
        destroyOnClose
        visible={showScanCenterForm}
        onCancel={handleCloseScanCenterForm}
      >
        {activeScanCenter && (
          <ScanCenterForm
            onSuccess={handleScanCenterFormSuccess}
            scanCenter={activeScanCenter}
          />
        )}
      </Modal>
    </div>
  );
}

export default ScanCenterList;
