import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { Admin } from "../../models/Admin/admin.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { convertJSONToFormData } from "../../shared/utils/dataFormatConverter";

export default class AdminService {
  static fetchAdmins(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.ADMINS)
      .then((response) => {
        const admins = deserialize(Admin, response.data["admins"]);
        onSuccess(admins);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showAdmin(
    adminId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.ADMINS + "/" + adminId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const admin = deserialize(Admin, response.data["admin"]);
        onSuccess(admin);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createAdmin(
    admin: Admin,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const adminJSON = { admin: serialize(admin) };
    const adminForm = convertJSONToFormData(adminJSON);
    axiosInstance
      .post(ApiRoutes.ADMINS, adminForm)
      .then((response) => {
        Notification({
          message: "Admin created!",
          type: NotificationTypes.SUCCESS,
        });
        const admin = deserialize(Admin, response.data["admin"]);
        onSuccess(admin);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateAdmin(
    admin: Admin,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.ADMINS + "/" + admin.id;
    const adminJSON = { admin: serialize(admin) };
    const adminForm = convertJSONToFormData(adminJSON);
    axiosInstance
      .put(API_URL, adminForm)
      .then((response) => {
        Notification({
          message: "Admin updated!",
          type: NotificationTypes.SUCCESS,
        });
        const admin = deserialize(Admin, response.data["admin"]);
        onSuccess(admin);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteAdmin(
    adminId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.ADMINS + "/" + adminId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
