import {
  AUTHENTICATED,
  SET_USER_ROLE,
  UNAUTHENTICATED,
} from "../definitions/authConstants";
import { UserRoleEnum } from "../../enums/userRole.enum";
import { ScanCenter } from "../../models/ScanCenter/scanCenter.model";
import { Doctor } from "../../models/Doctor/doctor.model";
import { Admin } from "../../models/Admin/admin.model";

export interface SetAuthenticatedAction {
  type: typeof AUTHENTICATED;
  payload: { authenticated: boolean; user?: ScanCenter | Doctor | Admin };
}

export interface SetUnauthenticatedAction {
  type: typeof UNAUTHENTICATED;
  payload: { authenticated: boolean };
}

export interface SetUserRoleAction {
  type: typeof SET_USER_ROLE;
  payload: { userRole: UserRoleEnum };
}

export const setAuthenticated = (
  user: ScanCenter | Doctor | Admin
): SetAuthenticatedAction => ({
  type: AUTHENTICATED,
  payload: {
    authenticated: true,
    user,
  },
});

export const setUnAuthenticated = (): SetUnauthenticatedAction => {
  return {
    type: UNAUTHENTICATED,
    payload: {
      authenticated: false,
    },
  };
};

export const setUserRole = (userRole: UserRoleEnum): SetUserRoleAction => {
  return {
    type: SET_USER_ROLE,
    payload: {
      userRole,
    },
  };
};
