import React, { useEffect, useState } from "react";
import "./adminProfile.scss";
import AppCard from "../../../shared/components/AppCard";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import ProfileService from "../../../services/Profile/profile.service";
import { Admin } from "../../../models/Admin/admin.model";
import { Form, Formik } from "formik";
import { adminFormValidation } from "../../Admins/AdminForm/adminFormValidation";
import { Button, Col, Modal, Row, Switch } from "antd";
import InputField from "../../../shared/components/InputField";
import FileUpload from "../../../shared/components/FileUpload";
import AppLoader from "../../../shared/components/AppLoader";
import CaseOverview from "../../../shared/components/CaseOverview";
import ChangePasswordForm from "../../../shared/components/ChangePasswordForm";

interface AdminProfileProps extends AuthReducerProps {}

function AdminProfile({ userRole }: AdminProfileProps) {
  const [formValues, setFormValues] = useState(new Admin());

  const [loading, setLoading] = useState(false);

  const [formLoading, setFormLoading] = useState(false);

  const [showChangePassword, setShowChangePassword] = useState(false);

  const handleSubmit = (values: Admin) => {
    const admin = Object.assign(new Admin(), values);
    setFormLoading(true);
    ProfileService.updateAdminProfile(
      admin,
      (admin: Admin) => {
        setFormValues(admin);
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  useEffect(() => {
    if (userRole) {
      setLoading(true);
      ProfileService.getProfile(
        userRole,
        (admin: Admin) => {
          setFormValues(admin);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  }, []);

  return (
    <div className="admin-profile">
      <AppCard>
        <h2>Profile</h2>
        {loading ? (
          <AppLoader loading={loading} />
        ) : (
          <Formik
            initialValues={formValues}
            onSubmit={handleSubmit}
            enableReinitialize
            validationSchema={adminFormValidation}
          >
            {({ values, isValid, setFieldValue }) => {
              return (
                <Form>
                  <Row gutter={[30, 0]}>
                    <Col span={12}>
                      <InputField
                        title="First Name"
                        type="text"
                        name="firstName"
                        placeholder="Enter First name"
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        title="Last Name"
                        type="text"
                        name="lastName"
                        placeholder="Enter last name"
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        title="Email"
                        type="email"
                        name="email"
                        readOnly
                        placeholder="Enter Email"
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        title="Mobile"
                        type="text"
                        name="mobileNumber"
                        placeholder="Enter Mobile"
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        title="State of registration"
                        type="text"
                        name="stateOfRegistration"
                        placeholder="Enter State"
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        title="Specialisation"
                        type="text"
                        name="specialisation"
                        placeholder="Enter Specialisation"
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        title="Medical council registration number"
                        type="text"
                        name="medicalCouncilRegistrationNumber"
                        placeholder="Enter registration number"
                      />
                    </Col>
                    <Col span={24}>
                      <InputField
                        title="Address"
                        type="textarea"
                        name="address"
                        placeholder="Enter address"
                      />
                    </Col>
                    <Col span={8}>
                      <div className="admin-profile__switch">
                        <span>
                          <Switch
                            checked={values?.emailNotification}
                            onChange={(checked) => {
                              setFieldValue("emailNotification", checked);
                            }}
                          />
                        </span>
                        <span>Email Notification</span>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="admin-profile__switch">
                        <span>
                          <Switch
                            checked={values?.telegramNotification}
                            onChange={(checked) => {
                              setFieldValue("telegramNotification", checked);
                            }}
                          />
                        </span>
                        <span>Telegram Notification</span>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="admin-profile__switch">
                        <span>
                          <Switch
                            checked={values?.addSignatureToReport}
                            onChange={(checked) => {
                              setFieldValue("addSignatureToReport", checked);
                            }}
                          />
                        </span>
                        <span>Add signature to report</span>
                      </div>
                    </Col>
                    <Col span={12}>
                      <FileUpload
                        name="doctorSignature"
                        title="Upload Signature"
                        setFieldValue={setFieldValue}
                        value={values.doctorSignature}
                      />
                      {values?.doctorSignatureUrl && (
                        <div className="mt-3">
                          <img
                            src={values?.doctorSignatureUrl}
                            alt="Doctor signature"
                            className="admin-profile__signature"
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                  <div className="admin-profile__submit-wrapper text-right">
                    <Button
                      onClick={() => setShowChangePassword(true)}
                      type="primary"
                      htmlType="button"
                      className="mr-2"
                    >
                      Change Password
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={formLoading}
                      disabled={!isValid || formLoading}
                    >
                      Update Profile
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
        <Modal
          destroyOnClose
          visible={showChangePassword}
          className="primary-modal"
          onCancel={() => setShowChangePassword(false)}
          footer={null}
          maskClosable={false}
        >
          <ChangePasswordForm onClose={() => setShowChangePassword(false)} />
        </Modal>
      </AppCard>
    </div>
  );
}

export default AuthContainer(AdminProfile);
