import React, { useState } from "react";
import "./adminForm.scss";
import { Form, Formik, FormikValues } from "formik";
import { Button, Col, Row } from "antd";
import InputField from "../../../shared/components/InputField";
import faker from "faker";
import { Admin } from "../../../models/Admin/admin.model";
import { adminFormValidation } from "./adminFormValidation";
import AdminService from "../../../services/Admin/admin.service";
import FileUpload from "../../../shared/components/FileUpload";

interface AdminFormProps {
  admin: Admin;
  onSuccess: (admin: Admin) => void;
}

function AdminForm({ admin, onSuccess }: AdminFormProps) {
  const [formLoading, setFormLoading] = useState(false);

  const handleSubmit = (values: FormikValues) => {
    const admin = Object.assign(new Admin(), values);
    if (!admin.id) {
      admin.password = `${admin?.firstName || ""}${admin?.lastName || ""}`;
    }
    setFormLoading(true);
    if (admin.id) {
      AdminService.updateAdmin(
        admin,
        (admin: Admin) => {
          onSuccess(admin);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    } else {
      AdminService.createAdmin(
        admin,
        (admin: Admin) => {
          onSuccess(admin);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  return (
    <div className="admin-form">
      <h2 className="text-primary">{admin.id ? "Edit Admin" : "Add Admin"}</h2>
      <Formik
        initialValues={admin}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={adminFormValidation}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <Form>
              <Row gutter={[30, 0]}>
                <Col span={12}>
                  <InputField
                    title="First Name"
                    type="text"
                    name="firstName"
                    placeholder="Enter First name"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Last Name"
                    type="text"
                    name="lastName"
                    placeholder="Enter last name"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Email"
                    type="email"
                    name="email"
                    placeholder="Enter Email"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Mobile"
                    type="text"
                    name="mobileNumber"
                    placeholder="Enter Mobile"
                  />
                </Col>
                {!values?.id && (
                  <Col span={12}>
                    <InputField
                      title="Password"
                      type="text"
                      value={`${values?.firstName || ""}${
                        values?.lastName || ""
                      }`}
                      name="password"
                      placeholder="Your Password"
                    />
                  </Col>
                )}
                <Col span={12}>
                  <FileUpload
                    name="doctorSignature"
                    title="Upload Signature"
                    setFieldValue={setFieldValue}
                    value={values.doctorSignature}
                  />
                </Col>
              </Row>
              <div className="executive-form__submit-wrapper text-right">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={formLoading}
                  disabled={!isValid || formLoading}
                >
                  {admin?.id ? "Update" : "Add"}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default AdminForm;
