import React, { useEffect, useState } from "react";
import AppCard from "../../../shared/components/AppCard";
import { Button, Table, Modal, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import "./adminList.scss";
import AdminForm from "../AdminForm";
import { Admin } from "../../../models/Admin/admin.model";
import AdminService from "../../../services/Admin/admin.service";
import { Doctor } from "../../../models/Doctor/doctor.model";
import AppLoader from "../../../shared/components/AppLoader";

interface AdminListProps {}

function AdminList(props: AdminListProps) {
  const [admins, setAdmins] = useState<Admin[]>([]);

  const [showAdminForm, setShowAdminForm] = useState(false);

  const [activeAdmin, setActiveAdmin] = useState(new Admin());

  const [loading, setLoading] = useState(false);

  const handleCloseAdminForm = () => setShowAdminForm(false);

  const handleAddAdmin = () => {
    setActiveAdmin(new Admin());
    setShowAdminForm(true);
  };

  const handleDeleteAdmin = (admin: Admin) => {
    // console.log(admin);
  };

  const handleAdminFormSuccess = (admin: Admin) => {
    setShowAdminForm(false);
    const adminIndex = admins.findIndex(
      (currentAdmin) => admin.id === currentAdmin.id
    );
    if (adminIndex >= 0) {
      admins[adminIndex] = admin;
    } else {
      admins.unshift(admin);
    }
    setAdmins([...admins]);
  };

  useEffect(() => {
    setLoading(true);
    AdminService.fetchAdmins(
      (admins: Admin[]) => {
        setAdmins(admins);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, []);

  const columns = [
    {
      title: "Name",
      key: "name",
      render: (name: string, admin: Admin) => (
        <span> {admin.getFullName()} </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
    },
    {
      title: "Action",
      key: "action",
      render: (text: string, admin: Admin) => {
        return (
          <div className="scan-centre-list_actions">
            <EditOutlined
              className="mr-3"
              onClick={() => {
                setShowAdminForm(true);
                setActiveAdmin(admin);
              }}
            />
            <Popconfirm
              title="Are you sure to delete this Admin?"
              placement="left"
              onConfirm={() => {
                handleDeleteAdmin(admin);
              }}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined />
            </Popconfirm>
          </div>
        );
      },
      width: 150,
    },
  ];

  return (
    <div className="user-list">
      <AppCard>
        <h2>
          Admins
          <Button
            type="primary"
            className="float-right"
            onClick={handleAddAdmin}
          >
            <PlusOutlined />
            Add Admin
          </Button>
        </h2>
        {loading ? (
          <AppLoader loading={loading} />
        ) : (
          <Table dataSource={admins} columns={columns} />
        )}
      </AppCard>
      <Modal
        className="primary-modal"
        footer=""
        destroyOnClose
        keyboard={false}
        maskClosable={false}
        visible={showAdminForm}
        onCancel={handleCloseAdminForm}
      >
        {activeAdmin && (
          <AdminForm onSuccess={handleAdminFormSuccess} admin={activeAdmin} />
        )}
      </Modal>
    </div>
  );
}

export default AdminList;
