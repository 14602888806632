import axiosInstance from "../../interceptor/axiosInstance";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import { UserRoleEnum } from "../../enums/userRole.enum";
import { Admin } from "../../models/Admin/admin.model";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { convertJSONToFormData } from "../../shared/utils/dataFormatConverter";
import { Doctor } from "../../models/Doctor/doctor.model";
import { ScanCenter } from "../../models/ScanCenter/scanCenter.model";
import ScanCenterProfile from "../../views/Profile/ScanCenterProfile";

export default class ProfileService {
  static getProfile(
    userRole: UserRoleEnum,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL =
      userRole === UserRoleEnum.ADMIN
        ? ApiRoutes.ADMIN_PROFILE
        : userRole === UserRoleEnum.DOCTOR
        ? ApiRoutes.DOCTOR_PROFILE
        : ApiRoutes.SCAN_CENTER_PROFILE;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        if (userRole === UserRoleEnum.ADMIN) {
          const admin = deserialize(Admin, response.data["admin"]);
          onSuccess(admin);
        } else if (userRole === UserRoleEnum.DOCTOR) {
          const doctor = deserialize(Doctor, response.data["doctor"]);
          onSuccess(doctor);
        } else {
          const scanCenter = deserialize(
            ScanCenter,
            response.data["scan_center"]
          );
          onSuccess(scanCenter);
        }
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updatePassword(
    userRole: UserRoleEnum,
    password: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL =
      userRole === UserRoleEnum.ADMIN
        ? ApiRoutes.ADMIN_PASSWORD
        : userRole === UserRoleEnum.DOCTOR
        ? ApiRoutes.DOCTOR_PASSWORD
        : ApiRoutes.SCAN_CENTER_PASSWORD;
    axiosInstance
      .put(API_URL, { password })
      .then((response) => {
        Notification({
          message: "Password updated",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateAdminProfile(
    admin: Admin,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const adminJSON = { admin: serialize(admin) };
    const adminForm = convertJSONToFormData(adminJSON);
    axiosInstance
      .put(ApiRoutes.UPDATE_ADMIN_PROFILE, adminForm)
      .then((response) => {
        Notification({
          message: "Profile updated",
          type: NotificationTypes.SUCCESS,
        });
        const admin = deserialize(Admin, response.data["admin"]);
        onSuccess(admin);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateDoctorProfile(
    doctor: Doctor,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const doctorJSON = { doctor: serialize(doctor) };
    const doctorForm = convertJSONToFormData(doctorJSON);
    axiosInstance
      .put(ApiRoutes.UPDATE_DOCTOR_PROFILE, doctorForm)
      .then((response) => {
        Notification({
          message: "Profile updated",
          type: NotificationTypes.SUCCESS,
        });
        const doctor = deserialize(Doctor, response.data["doctor"]);
        onSuccess(doctor);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateScanCenterProfile(
    scanCenter: ScanCenter,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const scanCenterJSON = { scan_center: serialize(scanCenter) };
    axiosInstance
      .put(ApiRoutes.UPDATE_SCAN_CENTER_PROFILE, scanCenterJSON)
      .then((response) => {
        Notification({
          message: "Profile updated",
          type: NotificationTypes.SUCCESS,
        });
        const scanCenter = deserialize(
          ScanCenter,
          response.data["scan_center"]
        );
        onSuccess(scanCenter);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
